@charset "utf-8";

@import '../../common/css/function';


@keyframes anime_ttl_deco_toph2_pc {
    0% { top: -50px; }
    30% { top: -20px; }
    100% { top: -20px; }
}
@keyframes anime_ttl_deco_toph2_sp {
    0% { top: spvw(-125); }
    30% { top: spvw(-57.5); }
    100% { top: spvw(-57.5); }
}

body#top {

    .top_cec_btn_01 {
        @extend .cec_btn_01;
        width: 400px;
        @include mq(sp) {
            width: 90%;
        }
    }
    .h2c_01 {
		&::before {
			top: -50px;
			height: 45px;
		}
		&::after {
			height: 15px;
			animation: anime_ttl_deco_toph2_pc 3s infinite;
		}
		> p {
			line-height: 1em;
			padding-top: .4em;
			font-size: 50px;
			font-family: Roboto;
			font-weight: 900;
			margin-bottom: .15em;
			color: $defcolor;
			@include mq(sp) {
				font-size: spvw(70);
			}
		}
        .subttl {
            color: $purple;
			font-family: 'hirakaku', 'YuGothic';
            line-height: 1em;
			font-size: 16px;
			font-weight: 900;
			display: inline-block;
			@include mq(sp) {
				font-size: spvw(26);
			}
        }
    }
}




/* ------------------------------- */
//
// main visual
//
/* ------------------------------- */
.top_mvbox {
    display: flex;
    width: 100%;
    height: 500px;
    @include mq(md2) {
        height: mdvw(500, 1400);
    }
    @include mq(sp) {
        height: spvw(580);
    }
    .top_mvlst {
        figure {
            display: block;
            width: 100vw;
            height: 500px;
            position: relative;
            img {
                width: 100%;
                position: absolute;
                left: 0;
                top: calc(250px - (500vw/28));
                @include mq(md2) {
                    top: 0;
                }
                @include mq(sp) {
                    top: 0;
                }
            }
        }
    }

    .slick-dots {
        width: auto;
        bottom: 10px;
        right: 120px;
        @include mq(md2) {
            right: mdvw(120, 1400);
        }
        @include mq(sp) {
            bottom: 1vw;
            right: 4vw;
        }
        li {
            width: 40px;
			height: auto;
			@include mq(sp) {
				width: 8vw;
			}
            button {
                width: 40px;
				@include mq(sp) {
					width: 8vw;
				}
                height: 3px;
                background-color: #ccc;
                padding: 0;
                &:before {
                    width: 0;
                    height: 3px;
                    background-color: #8973ce;
                    content: '';
                    border-radius: 3px;
                    text-align: center;
                    opacity: 1;
                }
            }
            &.slick-active {
                button {
                    &:before {
                        opacity: 1;
                        animation: dotsanime 4s linear forwards;
                    }
                }
            }

        }
    }
}




/* ------------------------------- */
//
// EMERGENCY
//
/* ------------------------------- */
.common_inc.inc_top_emergency {
	width: 100%;
	> ul {
		max-width: 1160px;
		width: 92%;
		margin: 0 auto;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		> li {
			width: 49%;
			padding: .5em 50px;
			box-sizing: border-box;
			@include mq(sp) {
				width: 100%;
				padding: .5em 0;
			}
			&.all {
				width: 100%;
			}
			p {
				font-size: 14px;
				color: #dc0000;
				display: inline-block;
				@include mq(sp) {
					font-size: spvw(24);
				}
			}
			a {
				font-size: 14px;
				position: relative;
				color: #dc0000;
				display: inline;
				word-break: break-all;
				@include mq(sp) {
					font-size: spvw(24);
				}
				// &:after {
				// 	position: absolute;
				// 	content: "";
				// 	width: .5em;
				// 	height: .5em;
				// 	border-top: #dc0000 1px solid;
				// 	border-right: #dc0000 1px solid;
				// 	transform: rotate(45deg);
				// 	bottom: .2em;
				// 	right: -.8em;
				// 	transition: right .3s;
				// }
				&:hover {
					text-decoration: none;
					// &:after {
					// 	right: -1.1em;
					// }
				}
			}

		}
	}
}


/* ------------------------------- */
//
// PICK UP
//
/* ------------------------------- */
.common_inc.inc_pickup {
	position: relative;
	margin: 65px 0 200px;
	@include mq(sp) {
		margin: 12vw 0 40vw;
	}
	&:before {
		content: "";
		position: absolute;
		left: 0;
		top: 108px;
		height: calc(100% - 70px);
		width: calc(50% + 380px);
		background: url(/common/img/inc/bg_pickup.png) top right / cover no-repeat;
		z-index: -1;

		@include mq(sp) {
			background: url(/common/img/inc/bg_pickup_sp.png) top left / cover no-repeat;
			width: pcnt(660, 750);
			top: 24vw;
			height: calc(100% - 10vw);
		}
	}

	.common_inc_main {
		position: relative;
		padding: 60px 0 40px;
		@include mq(md2) {
			overflow: hidden;
		}
		@include mq(sp) {
			height: auto;
			width: 100%;
			padding: spvw(120) 0 spvw(20);
		}
		.contents {
			margin-top: 0;
			width: 1400px;
			max-width: 1400px;
			@include mq(md2) {
				position: absolute;
				left: calc(50vw - 700px);
			}
			@include mq(sp) {
				width: auto;
				height: auto;
			}
		}
		.slick-slide {
			@include mq(sp) {
				margin: 0 3.33vw;
			}
		}
		.slick-track {
			margin: 0;
		}
		.slick-dots {
			bottom: -40px;
			li {
				width: 40px;
				height: auto;
				@include mq(sp) {
					width: 8vw;
				}
				button {
					width: 40px;
					height: 3px;
					background-color: #ccc;
					padding: 0;
					@include mq(sp) {
						width: 8vw;
					}
					&:before {
						width: 0;
						height: 3px;
						background-color: #8973ce;
						content: '';
						border-radius: 3px;
						text-align: center;
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&:before {
							opacity: 1;
							animation: dotsanime 4s linear forwards;
						}
					}
				}

			}
		}
	}
}
.inc_pickup_ul {
	display: block;
	.inc_pickup_li {
		@include mq(sp) {
			margin: 0;
		}
		> a {
			color: $defcolor;
			> dl {
				width: 260px;
				margin: 0 auto;
				@include mq(sp) {
					width: auto;
				}
				> dt {
					> figure {
						width: 100%;
						height: 260px;
						display: block;
						position: relative;
						overflow: hidden;
						@include mq(sp) {
							position: static;
							width: auto;
							height: auto;
						}
						> img {
							position: absolute;
							transform: scale(1);
							transition: transform .3s;
							@include mq(sp) {
								position: static;
							}
						}
					}
				}
				> dd {
					width: 100%;
					box-sizing: border-box;
					padding: 0;
					@include mq(sp) {
						width: auto;
						padding: 0;
					}
                    .inctxt_pick {
                        &_ttl {
                            font-size: 14px;
                            font-weight: bold;
                            margin: 1em 0 .5em;
                            line-height: 1.7em;
                            @include mq(sp) {
                                font-size: spvw(28);
                            }
                        }
                        &_txt {
                            font-size: 12px;
                            @include mq(sp) {
                                font-size: spvw(24);
                                margin: 0;
                            }
                        }
                        &_ico {
                            display: inline-block;
                            > span {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                font-size: 10px;
                                width: 60px;
                                height: 18px;
                                border-radius: 10px;
                            }
                            &.new {
                                > span {
                                    background-color: $purple;
                                    &:before {
                                        content: "NEW"; 
                                        color: $white;
                                        font-size: inherit;
                                    }
                                }
                            }
                            &.hot {
                                > span {
                                    background-color: #ff5d4c;
                                    &:before {
                                        content: "HOT"; 
                                        color: $white;
                                        font-size: inherit;
                                    }
                                }
                            }
                        }
					}
				}
			}
			&:hover {
				text-decoration: none;
				> dl {
					> dt {
						> figure {
							> img {
								transform: scale(1.05);
								@include mq(sp) {
									transform: scale(1);
								}
							}
						}
					}
					> dd {
						p {
							&.inctxt_pick_ttl {
								// color: $purple;
							}
						}
					}
				}
			}
			&[target="_blank"] {
				> dl {
					> dd {
						p {
							&.inctxt_pick_txt {
								position: relative;
								display: inline-block;
								&:after {
									content: "";
									position: absolute;
									width: 1.3em;
									height: 1.6em;
									right: -1.5em;
									bottom: -0.05em;
									display: inline-block;
									background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
								}
							}
						}
					}
				}
			}
		}
	}
}
body.ie {
	.inc_pickup_ul {
		.inc_pickup_li {
			> a {
				&[target="_blank"] {
					> dl {
						> dd {
							p {
								&.inctxt_pick_txt {
									&:after {
										width: 1.3em;
										height: 1.3em;
										bottom: 0.4em;
										background: transparent url(/common/img/ico/ico_blank.png) center center / cover no-repeat;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}






/* ------------------------------- */
//
// NEWS ニュース
//
/* ------------------------------- */
.common_inc.inc_news_top {
	margin: 100px 0 0;
	@include mq(sp) {
		margin: 22vw 0 0;
	}
	.contents.w900.inc_news {
		h2.h2c_01 {
			margin-bottom: 0px;
		}
		ul#top_search_ul {
			display: flex;
			width: 100%;
			margin: 30px auto 50px;
			position: relative;
			@include mq(sp) {
				margin: calc((20 / 750) * 100vw) 0 calc((70 / 750) * 100vw);
			}
			&:after {
				position: absolute;
				content: "";
				width: 50%;
				height: 2px;
				bottom: 0;
				left: 0;
				background-color: $purple;
				transition: left .3s;
			}
			&.l0:after { left: 0; }
			&.l1:after { left: 50%; }
			> li {
				width: 50%;
				text-align: center;
				border-bottom: 2px solid #eee;
				color: #aaa;
				font-size: 14px;
				padding: 1.3em 0;
				cursor: pointer;
				@include mq(sp) {
					letter-spacing: -.01em;
					font-size: spvw(28);
				}
				&.act {
					font-weight: bold;
					color: $purple;
					// border-bottom: 2px solid $purple;
				}
			}
		}
		.top_news_tab {
			position: relative;
			> div {
				position: absolute;
				width: 100%;
				opacity: 0;
				pointer-events: none;
				transition: opacity .4s;
				&.act {
					opacity: 1;
					pointer-events: all;
				}
			}
		}

		ul {
			&.cmn_news_list {
				&.seminar_event {
					> li {
						&.disnon { display: none; }
						> dl {
							flex-wrap: wrap;
							justify-content: flex-start;
							> dt {
								&.date {
									line-height: 2.4em;
								}
								&.category {
									width: 102px;
									margin-left: 16px;
									display: flex;
									align-items: center;
									height: 1.7em;
									>span{
										display: flex;
										font-size: 12px;
										color: $purple;
										line-height: 1em;
										height: 1em;
										padding: .3em 2em .4em;
										text-align: center;
									}
									&.event span { background: #ff5d4c; color: $white; border: none; }
									&.seminar span { background: #5330c1; color: $white; border: none; }
									&.end--reception span,
									&.event.end--reception span,
									&.seminar.end--reception span { background: #888; color: $white; border: none; }
									&.end--closed span,
									&.event.end--closed span,
									&.seminar.end--closed span { background: rgba(221,221,221,1); color: rgba(153,153,153,1); border: none; }
									&.end--reception {
										width: 180px;
										+ dd {
											width: calc(100% - 16px - 87px - 16px - 180px);
										}
									}
								}
							}
							> dd {
								width: calc(100% - 16px - 87px - 16px - 110px);
								margin-left: 16px;
								line-height: 2em;
								> a {
									&:hover {
									}
								}
							}
						}
					}
				}
				
				@include mq(sp) {
					&.seminar_event {
						> li {
							> dl {
								flex-wrap: wrap;
								justify-content: flex-start;
								> dt {
									&.date {
										width: 25%;
										font-size: 2.8vw;
									}
									&.category {
										width: auto;
										margin-left: 0;
										height: auto;
										>span{
											font-size: 2.6vw;
											height: auto;
											line-height: normal;
											padding: .1em 2em 0;
										}
										&.end--reception {
											width: auto;
											+ dd {
												width: 100%;
											}
										}
									}
								}
								> dd {
									font-size: 3.2vw;
									width: 100%;
									padding-left: 0;
									line-height: 1.6em;
									margin: .6em 0 1.2em;
								}
							}
						}
					}
					
				}
			}
		}
		
	}
}



/* ------------------------------- */
//
// REASON
//
/* ------------------------------- */
.top_reason {
	width: 100%;
	padding-top: 10px;
	margin-top: 120px;
	@include mq(sp) {
		padding-top: 5vw;
		margin-top: 5vw;
	}
	.top_reason_shape {
		margin-top: 220px;
		height: 420px;
		width: calc(50% + 500px);
		margin-left: calc(50% - 500px);
		background: transparent url(/top/img/bg_top_reason.png) center center / cover no-repeat;
		position: relative;
		@include mq(sp) {
			margin-top: 64vw;
			width: 100%;
			margin-left: 0;
			height: 100vw;
			background: transparent url(/top/img/bg_top_reason.png) center center / cover no-repeat;
		}
		&:after {
			position: absolute;
			content: "";
			height: 105px;
			width: 100%;
			left: 0;
			bottom: 0;
			background: transparent url(/top/img/shapeyourfuture.png) left bottom / contain no-repeat;
			@include mq(sp) {
				height: spvw(103);
				background: transparent url(/top/img/shapeyourfuture.png) left bottom / cover no-repeat;
			}
		}
	}
	.top_reason_main {
		position: relative;
		width: 100%;
		.top_reason_txtbox {
			position: absolute;
			width: 500px;
			top: 50px;
			left: calc(50% - 500px);
			text-align: center;
			z-index: +1;
			@include mq(sp) {
				width: 100%;
				left: 0;
			}

			h2.h2c_01 {
				margin-bottom: 3.5em;
				@include mq(sp) {
					margin-bottom: 68vw;
				}
			}
			p {
				line-height: 1.8em;
				font-size: 16px;
				color: $white;
				@include mq(sp) {
					font-size: spvw(30);
				}
			}
			a.cec_btn_01 {
				margin-top: 4em;
				width: 336px;
				@include mq(sp) {
					margin-top: 2em;
					width: spvw(600);
				}
				&::after {
					background-color: #b8a4f7;
				}
			}
		}
		.top_reason_imgbox {
			position: absolute;
			width: 50%;
			right: 0;
			z-index: +1;
			background: transparent url(/top/img/top_img_reason.jpg) center center / cover no-repeat;
			height: 440px;
			@include mq(sp) {
				width: pcnt(640, 750);
				top: 42vw;
				height: 52vw;
			}
		}
	}
}



/* ------------------------------- */
//
// BUSINESS
//
/* ------------------------------- */
.top_business {
	width: 100%;
	margin-top: 100px;
	padding-top: 10px;
	position: relative;
	padding-bottom: 70px;
	@include mq(sp) {
		margin-top: 10vw;
		padding: 5vw 0 28vw;
	}
	&::after {
		position: absolute;
		content: "";
		top: 120px;
		height: calc(100% - 120px);
		width: 100%;
		z-index: -1;
		background: transparent url(/top/img/bg_bnusiness.png) center center / cover no-repeat;
		@include mq(sp) {
			top: 30vw;
			height: calc(100% - 47vw);
		}
	}
	.top_business_all {
		max-width: 1240px;
		width: 100%;
		margin: 0 auto;
		.top_business_box {
			display: flex;
			max-width: 1160px;
			// width: pcnt(1160, 1240);
			width: 100%;
			flex-wrap: wrap;
			margin: 0 auto;
			@include mq(sp) {
				display: block;
				width: pcnt(600, 750);
			}
			.top_business_title {
				order: 2;
				width: pcnt(340, 1160);
				padding-top: 65px;
				@include mq(md) {
					position: relative;
					overflow: hidden;
				}
				@include mq(sp) {
					order: 1;
					width: 100%;
					padding-top: 16vw;
				}
				h2.h2c_01 {
					margin-bottom: 1.5em;
					margin-right: -40px;
					@include mq(md) {
						left: 10px;
						margin-right: 0px;
					}
					@include mq(sp) {
						margin-right: 0;
						margin-bottom: 1em;
					}
				}
			}
			.top_business_lnk {
				a {
					dl {
						width: 100%;
						display: flex;
						@include mq(sp) {
							display: block;
						}
						dd {
							width: pcnt(420, 820);
							overflow: hidden;
							@include mq(sp) {
								width: 100%;
							}
							img {
								display: block;
								width: 100%;
								transform: scale(1);
								transition: transform .4s;
							}
						}
						dt {
							width: pcnt(400, 820);
							background-color: $white;
							display: flex;
							align-items: center;
							justify-content: center;
							text-align: center;
							@include mq(sp) {
								width: 100%;
								padding: spvw(60) 0;
							}
							h3 {
								text-align: center;
								font-size:20px;
								font-weight: bold;
								color: $defcolor;
								@include mq(sp) {
									font-size: spvw(36);
								}
								span {
									font-size: 20px;
									font-family: 'Roboto Condensed';
									color: $purple;
									@include mq(sp) {
										font-size: spvw(32);
									}
								}
							}
							p {
								text-align: center;
								font-size: 14px;
								color: $defcolor;
								margin-top: .5em;
								line-height: 1.7;
								@include mq(sp) {
									font-size: spvw(26);
								}
							}
						}
					}
					&:hover {
						text-decoration: none;
						dl {
							dd {
								img {
									transform: scale(1.05);
								}
							}
						}
					}
				}
			}
			.top_business_di {
				order: 1;
				width: pcnt(820, 1160);
				@include mq(sp) {
					order: 2;
					width: 100%;
				}
			}
			.top_business_si {
				order: 3;
				width: pcnt(820, 1160);
				margin-left: auto;
				margin-top: 40px;
				@include mq(sp) {
					margin-top: spvw(40);
					width: 100%;
				}
			}
			.top_business_sb {
				order: 4;
				width: pcnt(820, 1160);
				margin-right: auto;
				margin-top: 40px;
				@include mq(sp) {
					margin-top: spvw(40);
					width: 100%;
				}
			}
		}
	}
}



/* ------------------------------- */
//
// SERVICE SEARCH
//
/* ------------------------------- */
.top_service_title {
	width: 100%;
	max-width: 1060px;
	padding: 0 10px;
	box-sizing: border-box;
	margin: 160px auto -45px;
    position: relative;
    height: 10px;
    @include mq(sp) {
		text-align: center;
		position: static;
		margin: 12vw auto 0;
		height: 36vw;
    }

	h2.h2c_01 {
		position: absolute;
		margin-bottom: 1.5em;
		@include mq(sp) {
			position: relative;
			width: 100%;
		}
	}
}
.search_box {
	width: 100%;
	max-width: 1060px;
	padding: 65px 10px 0;
	box-sizing: border-box;
	margin: 90px auto 60px;
	display: flex;
    @include mq(sp) {
		display: block;
		margin: 24vw 0 16vw;
		padding: 0;
    }

	h2.h2c_01,
	#sol_search_form {
		display: none;
	}
    ul#search_ul {
        width: pcnt(320, 1040);
		margin: 0;
        position: relative;
		height: 460px;
		border-right: #bbb 1px solid;
		box-sizing: border-box;
		padding: 100px 0 0 pcnt(100, 1040);
        @include mq(sp) {
			width: 100%;
			padding: 0;
			display: flex;
			border-right: none;
			border-bottom: #bbb 1px solid;
			height: auto;
			margin-bottom: 8vw;
        }
        &.key:after { left: 0; }
        &.section:after { left: 25%; }
        &.purpose:after { left: 50%; }
        &.solution:after { left: 75%; }
        > li {
            width: 100%;
            text-align: left;
            color: rgba(170,170,170,1);
            font-size: 14px;
            padding: 1.3em 0;
			position: relative;
            cursor: pointer;
			transition: all .3s;
            @include mq(sp) {
				width: 33%;
				text-align: center;
				font-size: spvw(28);
				padding: 0 0 6vw;
            }
			&:hover {
				color: $purple;
			}
            &.act {
                font-weight: bold;
                color: $purple;
            }
			&:nth-child(1), &:nth-child(2), &:nth-child(3) {
				&:after {
					position: absolute;
					content: "";
					height: 1px;
					top: .7em;
					background-color: #bbb;
					top: 2em;
					right: 0;
					width: 0%;
					transition: width .3s;
					@include mq(sp) {
						width: 1px;
						height: 0;
						right: auto;
						top: auto;
						left: 50%;
						bottom: 0vw;
						transition: height .3s;
					}
				}
			}
            &.here {
				&:nth-child(1), &:nth-child(2), &:nth-child(3) {
					&:after {
						width: 40%;
						@include mq(md3) {
							width: calc(23.5vw - 157.7px);
						}
						@include mq(sp) {
							width: 1px;
							height: 4vw;
						}
					}
				}
			}
			&:nth-child(4) {
				position: relative;
				margin-top: 20px;
				line-height: 1em;
				&:after {
					position: absolute;
					content: "";
					width: .5em;
					height: .5em;
					border-top: $purple 1px solid;
					border-right: $purple 1px solid;
					transform: rotate(45deg);
					top: 1.6em;
					left: 7em;
					transition: all .3s;
				}
				@include mq(sp) {
					position: absolute;
					margin: 0;
					top: -24vw;
					border-radius: 2em;
					border: $purple 1px solid;
					padding: 1.5em 0;
					width: 48vw;
					left: calc(50% - 24vw);
					line-height: 0;
					color: $purple;
					br.sp {
						display: none !important;
					}
					&:after {
						position: absolute;
						content: "";
						width: .5em;
						height: .5em;
						border-top: $purple 1px solid;
						border-right: $purple 1px solid;
						transform: rotate(45deg);
						top: 1.16em;
						left: auto;
						right: 1.5em;
					}
				}
				&:hover {
					&:nth-child(4) {
						&:after {
							left: 7.3em;
							@include mq(sp) {
								left: auto;
							}
						}
					}
				}
			}
        }
    }
    .search_tab {
        position: relative;
        width: pcnt(720, 1040);
        @include mq(sp) {
			width: 100%;
        }
        > div {
            position: absolute;
            opacity: 0;
            pointer-events: none;
            transition: opacity .7s;
            &.act {
                opacity: 1;
                pointer-events: all;
            }
            &.search_tab_key {
            }
            &.search_tab_solution {
				display: none;
            }
            ul.search_list {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                > li {
                    width: 25%;
                    display: block;
                    position: relative;
					text-align: center;
					box-sizing: border-box;
					padding: 1% 3%;
					cursor: pointer;
                    @include mq(sp) {
                        margin-right: 0;
                        width: 50%;
						padding: 3% 3%;
                        margin-bottom: spvw(20);
                    }
					&:nth-child(n+5) {
						margin-top: 40px;
						@include mq(sp) {
							margin-top: 0;
						}
					}
                    img {
                        width: 50%;
						min-height: 78px;
						opacity: 1;
						transition: opacity .4s;
                    }
					&:hover {
						img {
							opacity: .6;
						}
						div {
							color: $purple;
						}
						@include mq(sp) {
							border: #f2f2f2 1px solid;
							background-color: #f2f2f2;
							color: $defcolor;
						}
					}
                    div {
                        width: 100%;
                        box-sizing: border-box;
                        background-repeat: no-repeat;
                        background-position: left 13% center;
                        background-size: 10%;
						font-weight: 900;
						margin-top: 0.5em;
                        &.chk {
                            background-color: #eee8ff;
                            border: #c8b9f9 1px solid;
                        }
                        @include mq(sp) {
                            border-radius: spvw(100);
                            background-size: 10%;
                        }
                        > p {
                            box-sizing: border-box;
                            margin: 0;
                            font-size: 14px;
                            @include mq(sp) {
                                font-size: spvw(28);
                            }
                        }
                    }
                }
            }
        }
    }
}
body.ie {
	.search_box {
		.search_tab {
			> div {
				&.search_tab_solution {
					max-width: 1200px;
					width: 100%;
				}
			}
		}
	}
}






.top_service_lnk {
	width: 100%;
	text-align: center;
	margin-top: 80px;
	@include mq(sp) {
		width: spvw(588);
		margin: 0 auto;
	}
	.cec_btn_01 {
		width: 340px;
		margin: 0 auto;
		@include mq(sp) {
			width: 100%;
		}
	}
}

body.ie {
	.search_box {
		.search_tab {
			> div {
				ul.search_list {
					width: 720px;
					@include mq(md5) {
						width: mdvw(720, 1040);
					}
					> li {
						width: 25%;
						img {
							width: 70%;
							vertical-align: top;
						}
					}
				}
			}
		}
	}
}




/* ------------------------------- */
//
// CASE STUDY
//
/* ------------------------------- */
.common_inc.inc_case_top {
	text-align: center;
	position: relative;
	margin: 50px 0 120px;
	@include mq(sp) {
		margin: spvw(20) 0 spvw(128);
	}
	.common_inc_main {
		text-align: left;
		@include mq(md) {
			overflow: hidden;
		}
		@include mq(sp) {
			width: 100%;
		}
		h2.h2c_01 {
			margin-bottom: 1.5em;
			@include mq(sp) {
				margin-bottom: 1em;
			}
		}
		position: relative;
		.contents {
			width: 1200px;
			max-width: 1200px;
			@include mq(md) {
				position: absolute;
				// left: calc(50vw - 672px);
				left: calc(50vw - 600px);
			}
			@include mq(sp) {
				width: 100%;
			}
			.inc_case_ul {
		
				margin: 0 auto;
				@include mq(md3) {
					width: 100%;
				}
		
				@include mq(sp) {
					flex-direction: column;
				}
		
				.inc_case_li {
					width: pcnt(320, 1040);
					// padding: 0 20px;
					@include mq(sp) {
						width: 100%;
						border-top: solid 1px #dddddd;
						padding: spvw(50) 0 0;
						margin: spvw(50) 0 0;
		
						&:first-of-type {
							border-top: none;
							margin: 0;
							padding: 0;
						}
						// &:last-of-type {
						// 	border-bottom: none;
						// }
					}
					> a {
						color: $defcolor;
						line-height: 1.7;
						dl {
							width: 320px;
							margin: 0 auto;
							@include mq(sp) {
								display: block;
								width: 72vw;
								margin: 0 auto;
							}
							dt {
								@include mq(sp) {
									width: 100%;
								}
								figure {
									margin-bottom: 20px;
									display: block;
									width: 320px;
									height: 200px;
									position: relative;
									overflow: hidden;
									@include mq(md) {
										width: 100%;
										// height: 20.6vw;
									}
									@include mq(sp) {
										overflow: unset;
										width: auto;
										height: auto;
									}
									img {
										display: block;
										position: absolute;
										transform: scale(1);
										transition: transform .3s;
										width: 100%;
										height: 100%;
										object-fit: cover;
										@include mq(sp) {
											position: static;
										}
			
									}
								}
							}
		
							dd {
								@include mq(sp) {
									width: 100%;
								}
							}
						}
						.inctxt_case {
							&_ttl {
								font-size: 16px;
								margin-bottom: 0.4em;
								font-weight: bold;
								letter-spacing: -.02em;
								@include mq(sp) {
									font-size: spvw(28);
								}
							}
			
							&_subTtl {
								font-size: 12px;
								color: #747377;
								margin-bottom: .8em;
			
								@include mq(sp) {
									margin-bottom:spvw(25);
									font-size: spvw(24);
								}
							}
			
							&_txt {
								margin-bottom: .85em;
								font-size: 14px;
			
								@include mq(sp) {
									margin-bottom: spvw(25);
									font-size:spvw(28);
								}
							}
			
							&_blank {
								display: block;
								>span {
									margin-bottom: .45em;
									font-size: 12px;
									position: relative;
									display: inline-block;
									&:after {
										content: "";
										position: absolute;
										width: 1.1em;
										height: 1.4em;
										right: -1.3em;
										bottom: 0.1em;
										display: inline-block;
										background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
									}
				
									@include mq(sp) {
										margin-bottom: spvw(25);
										font-size:spvw(24);
									}
								}
							}
			
							&_lnk {
								margin-bottom: 1.45em;
								font-size: 14px;
			
								@include mq(sp) {
									margin-bottom: spvw(25);
									font-size:spvw(28);
								}
							}
			
							&_ico {
								display: inline-block;
								font-size: 12px;
								border-radius: 1em;
								border: solid 1px #c8b9f9;
								color: #5330c1;
								background-color: #FFFFFF;
								padding: 0 0.8em;
								letter-spacing: -.05em;
								margin-bottom: .4em;
								margin-right: .6em;
								@include mq(sp) {
									font-size:spvw(20);
								}
							}
						}
						&:hover {
							text-decoration: none;
							dl {
								dt {
									figure {
										img {
											transform: scale(1.05);
											@include mq(sp) {
												transform: scale(1);
											}
										}
									}
								}
							}
							p.inctxt_case_ttl {
								// color: $purple;
							}
						}
						&[target="_blank"] {
							> dl {
								> dd {
									p {
										&.inctxt_case_lnk {
											position: relative;
											display: inline-block;
											&:after {
												content: "";
												position: absolute;
												width: 1.3em;
												height: 1.6em;
												right: -1.5em;
												bottom: -0.05em;
												display: inline-block;
												background: transparent url(/common/img/ico/ico_blank.svg) right top / contain no-repeat;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		.slick-slide {
			@include mq(sp) {
				margin: 0 3.33vw;
			}
		}
		.slick-track {
			margin: 0;
		}
		.slick-dots {
			bottom: -40px;
			li {
				width: 40px;
				height: auto;
				@include mq(sp) {
					width: 8vw;
				}
				button {
					width: 40px;
					@include mq(sp) {
						width: 8vw;
					}
					height: 3px;
					background-color: #ccc;
					padding: 0;
					&:before {
						width: 0;
						height: 3px;
						background-color: #8973ce;
						content: '';
						border-radius: 3px;
						text-align: center;
						opacity: 1;
					}
				}
				&.slick-active {
					button {
						&:before {
							opacity: 1;
							animation: dotsanime 4s linear forwards;
						}
					}
				}

			}
		}
	}
	&:before {
		content: "";
		position: absolute;
		right: 0;
		top: 111px;
		height: calc(100% - 137px);
		width: calc(50% + 320px);
		background: url(/common/img/inc/bg_jirei.png) top left / cover no-repeat;
		z-index: -1;

		@include mq(sp) {
			background: url(/common/img/inc/bg_jirei_sp.png) top left / cover no-repeat;
			width: pcnt(560, 750);
			top: 21.5vw;
			height: calc(100% - 29vw);
		}
	}
	.cec_btn_01 {
		text-align: center;
		width: 340px;
		margin: 20px 0 0;
		@include mq(sp) {
			margin-top: 0;
		}
	}

}
body.ie {
	.common_inc.inc_case_top {
		.common_inc_main {
			.contents {
				.inc_case_ul {
					.inc_case_li {
						> a {
							.inctxt_case {
								&_blank {
									>span {
										&:after {
											width: 1.3em;
											height: 1.3em;
											bottom: 0.4em;
											background: transparent url(/common/img/ico/ico_blank.png) center center / cover no-repeat;
										}
									}
								}
							}
							&[target="_blank"] {
								> dl {
									> dd {
										p {
											&.inctxt_case_lnk {
												&:after {
													width: 1.3em;
													height: 1.3em;
													bottom: 0.4em;
													background: transparent url(/common/img/ico/ico_blank.png) center center / cover no-repeat;
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
	}
}





/* ------------------------------- */
//
// BLINK
//
/* ------------------------------- */
ul {
	&.top_blink {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		margin-bottom: 110px;
		@include mq(sp) {
			margin-bottom: spvw(160);
		}
		> li {
			display: block;
			width: 50%;
			height: 300px;
			@include mq(sp) {
				height: spvw(375);
				width: 100%;
			}
			> a {
				display: flex;
				width: 100%;
				height: 100%;
				justify-content: center;
				align-items: center;
				text-align: center;
				position: relative;
				overflow: hidden;
				i {
					display: block;
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transform: scale(1);
					transition: transform .3s;
				}
				p {
					font-size: 24px;
					line-height: 1.4em;
					text-align: center;
					color: $white;
					font-weight: 900;
					position: relative;
					z-index: 1;
					@include mq(sp) {
						font-size: spvw(40);
					}
					span {
						font-size: 16px;
						line-height: 1em;
						@include mq(sp) {
							font-size: spvw(24);
						}
					}
				}
				&:hover {
					text-decoration: none;
					i {
						transform: scale(1.05);
					}
				}
				
			}
			&:first-child {
				a {
					i {
						background: transparent url(/top/img/bg_blink_esg.jpg) center center / cover no-repeat;
						@include mq(sp) {
							background: transparent url(/top/img/bg_blink_esg_sp.jpg) center center / cover no-repeat;
						}
					}
				}
			}
			&:nth-child(2) {
				a {
					i {
						background: transparent url(/top/img/bg_blink_ir.jpg) center center / cover no-repeat;
						@include mq(sp) {
							background: transparent url(/top/img/bg_blink_ir_sp.jpg) center center / cover no-repeat;
						}
					}
				}
			}
		}
	}
}


// @import './topmove';